import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { okaidia } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { useTranslate } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './EmbeddedCode.css'

import iconCheck from '/images/icons/check.raw.svg'
import iconCopy from '/images/icons/icon-copy.raw.svg'

export function EmbeddedCode({ code, language }) {
  const reportError = useReportError()
  const [copied, setCopied] = React.useState(false)
  const { __ } = useTranslate()

  return (
    <div className={styles.component}>
      {/* eslint-disable @kaliber/layout-class-name */}
      <SyntaxHighlighter
        language={language || 'text'}
        style={okaidia}
        className={styles.syntaxHighlighter}
      >
        {code || ''}
      </SyntaxHighlighter>
      {/* eslint-enable @kaliber/layout-class-name */}
      <div className={styles.controls}>
        <span className={styles.languageTitle}>
          {language?.toUpperCase()}
        </span>
        <button
          onClick={handleClick}
          data-x='embedded-code-copy'
          aria-label={__`embedded-code-copy`}
          className={cx(styles.copyButton, copied && styles.isCopied)}
        >
          <Icon icon={copied ? iconCheck : iconCopy} />
        </button>
      </div>
    </div>
  )

  function handleClick() {
    if (!window.navigator || copied) return

    copyToClipboard({ code })
    setCopied(true)

    setTimeout(() => setCopied(false), 3000)
  }

  async function copyToClipboard({ code }) {
    await navigator.clipboard.writeText(code).catch(reportError)
  }
}
